import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomModal } from "../../../../../../components/ui/CustomModal/CustomModal";
import { Button } from "../../../../../../components/ui/Buttons/Button/Button";
import { CustomInput } from "../../../../../../components/ui/CustomInput/CustomInput";
import { ApiService } from "../../../../../../services/ApiService";
import { errorToast, warningToast } from "../../../../../../utils/helpers/customToast.helper";
import { useTranslation } from "../../../../../../hooks";
import { ButtonLoader } from "../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { isWrongLength } from "../../../../../../utils/helpers/isWrongLength";
import "../FolderModal.scss";

export const AddFolderModal = ({ isOpen, onClose, setFolders }) => {
  const {
    cabinet: { orders: { folders: { modals: { createFolder, inputLabel, save, cancel, folderAlreadyExist } } } }
  } = useTranslation();

  const { lang } = useParams();

  const { activeData } = useSelector((state) => state.content);

  const [folderName, setFolderName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const addFolder = async () => {
    setIsLoading(true);

    try {
      const { status, data } = await ApiService.addFolder(folderName, activeData?.proxy?.code);

      if (status === 208) {
        return warningToast(folderAlreadyExist, lang);
      }

      if (status !== 200) {
        throw Error();
      }

      setFolders((prev) => [...prev, data]);
      setFolderName("");
      onClose();

    } catch (e) {
      errorToast("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const minLength = 3;
    const maxLength = 20;

    if (isWrongLength(folderName, minLength, maxLength, lang)) {
      return;
    }

    addFolder();
  };

  return (
    <CustomModal open={isOpen} onClose={onClose}>
      <form className="folder-modal" onSubmit={handleSubmit}>
        <div className="folder-modal__title">{createFolder}</div>
        <CustomInput
          label={inputLabel}
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />
        <div className="folder-modal__actions">
          <Button tab="default" onClick={onClose}>
            {cancel}
          </Button>
          <Button tab="confirm" type="submit" disabled={isLoading}>
            {isLoading ? <ButtonLoader /> : save }
          </Button>
        </div>
      </form>
    </CustomModal>
  );
};
