import { FeedbackCard } from "../../../../components/common/Feedback/FeedbackCard/FeedbackCard";
import { NoDataComponent } from "../../../../components/common/NoDataComponent/NoDataComponent";
import "./FeedbackList.scss";

export const FeedbackList = ({ reviews }) => (
    <div className="feedback-list">
      <div className="feedback-list__container">
        {reviews?.length ? (
          reviews.map((review) => (
            <FeedbackCard key={review.id} review={review} />
          ))
        ) : (
          <NoDataComponent />
        )}
      </div>
    </div>
  );
