import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomModal } from "../../../../../../components/ui/CustomModal/CustomModal";
import { Button } from "../../../../../../components/ui/Buttons/Button/Button";
import { CustomInput } from "../../../../../../components/ui/CustomInput/CustomInput";
import { ApiService } from "../../../../../../services/ApiService";
import { errorToast, warningToast } from "../../../../../../utils/helpers/customToast.helper";
import { useDispatchedActions, useTranslation } from "../../../../../../hooks";
import { ButtonLoader } from "../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { isWrongLength } from "../../../../../../utils/helpers/isWrongLength";
import "../FolderModal.scss";

export const EditFolderModal = ({ isOpen, onClose, currentFolder, setFolders }) => {
  const {
    cabinet: { orders: { folders: { modals: { editName, inputLabel, save, cancel, folderAlreadyExist } } } }
  } = useTranslation();

  const { lang } = useParams();

  const { setSelectedFolder } = useDispatchedActions();

  const [isLoading, setIsLoading] = useState(false);
  const [folderName, setFolderName] = useState(currentFolder?.label);

  useEffect(() => {
    setFolderName(currentFolder?.label);
  }, [currentFolder]);

  const changeFolderName = async () => {
    if (folderName === currentFolder.label) {
      onClose();
      return;
    }

    setIsLoading(true);

    try {
      const { data, status } = await ApiService.editFolderName(currentFolder.value, folderName);

      if (status === 208) {
        return warningToast(folderAlreadyExist, lang);
      }

      if (status !== 200) {
        throw Error();
      }

      setFolders((prev) => [...prev].map((folder) => {
        if (folder.id === data.id) {
          return { ...folder, name: data.name };
        }

        return folder;
      }));

      setSelectedFolder({ value: data.id, label: data.name });
      onClose();

    } catch (e) {
      errorToast("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const minLength = 3;
    const maxLength = 20;

    if (isWrongLength(folderName, minLength, maxLength, lang)) {
      return;
    }

    changeFolderName();
  };

  return (
    <CustomModal open={isOpen} onClose={onClose}>
      <form className="folder-modal" onSubmit={handleSubmit}>
        <div className="folder-modal__title">{editName}</div>
        <CustomInput
          label={inputLabel}
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />
        <div className="folder-modal__actions">
          <Button tab="default" onClick={onClose}>
            {cancel}
          </Button>
          <Button tab="confirm" type="submit" disabled={isLoading}>
            { isLoading ? <ButtonLoader /> : save }
          </Button>
        </div>
      </form>
    </CustomModal>
  );
};
