export const defaultPrice = {
  amount: 0,
  price: 0,
  success: true,
  priceMobileOperator: null,
  priceMobileRotation: null
};

export const defaultSeo = {
  footerText: "",
  footerTitle: "",
  mainPageTitle: "",
  mainPageDescription: "",
  metaDescription: "",
  metaTitle: ""
};

export const initialCountryParams = {
  id: "",
  code: ""
}

export const PROXY_TYPES = {
  ipv4: {
    value: "ipv4",
    label: "IPv4",
    code: "IPv4"
  },
  ipv6: {
    value: "ipv6",
    label: "IPv6",
    code: "IPv6"
  },
  mobile: {
    value: "mobile",
    label: "Mobile",
    code: "MOBILE"
  },
  isp: {
    value: "isp",
    label: "ISP",
    code: "ISP"
  }
};

export const IPV4_MIN_QUANTITY = 1;
export const ISP_MIN_QUANTITY = 1;
export const IPV6_MIN_QUANTITY = 10;
export const MOBILE_MIN_QUANTITY = 1;

export const REVIEWS_PER_PAGE = 12;
