import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "../../components/ui/Container/Container";
import { REVIEWS_PER_PAGE } from "../../utils/constants/defaultData.constant";
import { useTranslation } from "../../hooks";
import { errorToast } from "../../utils/helpers/customToast.helper";
import { ApiService } from "../../services/ApiService";
import { FeedbackPagination } from "./components/FeedbackPagination/FeedbackPagination";
import { FeedbackList } from "./components/FeedbackList/FeedbackList";
import "./FeedbackPage.scss";

const FeedbackPage = () => {
  const { lang } = useParams();

  const { titleMain } = useTranslation().reviews;

  const { content, reviewsInitData } = useSelector((state) => state.content);

  const [reviews, setReviews] = useState(reviewsInitData);
  const [localSize, setLocalSize] = useState(REVIEWS_PER_PAGE);
  const [localCurrentPage, setLocalCurrentPage] = useState(1);
  const [positionY, setPositionY] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!positionY) {
      return;
    }

    window.scrollTo(0, positionY);

    setPositionY(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews]);

  useEffect(() => {
    setLocalSize(REVIEWS_PER_PAGE);
    setLocalCurrentPage(1);
  }, [lang]);

  useEffect(() => {
    (async function () {
      try {
        const { data, status } = await ApiService.getAllReviews();

        if (status !== 200) {
          throw status;
        }

        setReviews(data);
      } catch (status) {
        errorToast("Something went wrong");
      }
    })();
  }, []);

  const reviewsByLocale = reviews[lang] || [];

  const reviewsData = {
    totalElements: reviewsByLocale?.length || 0,
    totalPages: reviewsByLocale?.length ? reviewsByLocale?.length / localSize : 0
  };

  const isPaginationVisible = reviewsData?.totalElements > REVIEWS_PER_PAGE;

  const startIndex = (localCurrentPage - 1) * localSize;
  const endIndex = startIndex + localSize;

  const slicedReviews = reviewsByLocale.slice(startIndex, endIndex);

  return (
    <div className="feedback-page">
      <Container>
        <div className="feedback-page__header">
          <h1>
            {content?.data[lang]?.metaDateReviews?.title || titleMain}
          </h1>
        </div>
        <div className="feedback-page__content">
          <FeedbackList reviews={slicedReviews} />
          {isPaginationVisible && (
            <FeedbackPagination
              reviewsData={reviewsData}
              localCurrentPage={localCurrentPage}
              setLocalCurrentPage={setLocalCurrentPage}
              setLocalSize={setLocalSize}
              localSize={localSize}
              setPositionY={setPositionY}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default FeedbackPage;
